
// @ts-ignore
const Message = ({msg}) => {
    console.log('msg: ', msg)
    return (
        <div className={`message`}>
            <h3> {msg.subject} </h3>
            <p> {msg.data} </p>
            <small>{msg.time}</small>
        </div>
    );
};

export default Message;