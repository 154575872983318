import React, {useEffect, useState} from 'react';
import '../../styles.css'
// import React, {useState} from 'react';
// import type {SizeType} from 'antd/es/config-provider/SizeContext';
// import { connect, consumerOpts, headers, JSONCodec } from 'nats.ws';
// import { connect, consumerOpts, headers, JSONCodec, StringCodec }  from '../../../node_modules/nats.ws/lib/src/mod.js'
import {
    connect,
    consumerOpts,
    JSONCodec,
    StringCodec,
    headers,
    JetStreamManager,
    JetStreamSubscription,
    JetStreamClient, NatsError, Msg, nanos, AckPolicy
} from "nats.ws";
import Message from "../../Message";
import Messages from "../../Messages";
import {NatsConnectionImpl} from "nats.ws/lib/nats-base-client/nats";
import {NatsConnection} from "nats.ws/lib/nats-base-client/internal_mod";
import throttle from "../../throttle";
import {JetStreamPullSubscription} from "nats.ws/lib/nats-base-client/types";
// import Streams from "../../Streams";
// import Consumers from "../../Consumers";
// import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
// dotenv.config()
let natsConnect: NatsConnection
let jsonCodec = JSONCodec;
let err:  NatsError;
let msg: Msg;
export const PostList: React.FC = () => {
    const sc = StringCodec();
    const server = "wss://hub.thesharedmind.com"
    const [nc, setConnection] = useState(natsConnect)
    const [lastError, setError] = useState("")
    // const [subj, setSubj] = useState('whiteboard.*')
    const [subj, setSubj] = useState('>')
    const [jc, setJc] = useState(jsonCodec)
    const [nats, setNats] = useState(undefined)
    const [context, setContext] = useState(undefined)
    const [playList1, setPlayList1] = useState([])
    const [playlistNum, setPlaylistNum] = useState(0)
    const [messages, setMessages] = useState([])
    const [streams, setStreams] = useState([])
    const [consumers, setConsumers] = useState([])
    const [js, setJs] = useState<JetStreamClient | undefined>(undefined)
    const [jsm, setJsm] = useState<JetStreamManager | undefined>(undefined)
    const [playlistPullSub, setPlaylistPullSub] = useState<JetStreamPullSubscription | undefined>(undefined)
    let count = 0
    let key = 0
    let key2 = 0
    let key3 = 0
    let defaultChannel = 'pn.ch1'
    const PLAYLIST_ADDED = 'playlist.added';
    let playlistSub: JetStreamPullSubscription | undefined;
    let _playlist1: any[] = []
    // @ts-ignore
    const addMessage = (err, msg) => {
        key++
        const {subject, reply} = msg;
        const data = sc.decode(msg.data)
        const m = {subject, reply, data, key, time: new Date().toUTCString()}

        // console.log('m: ', m)
        // @ts-ignore
        // messages.push(m)
        messages.unshift(m)
        const a = messages.slice(0, 10)
        setMessages(a)
        // setMessages(messages)
        // setSubj(m.subject)
        // console.info(msg.subject)
    }
    // @ts-ignore
    const addJCMessage = (msg) => {
        key3++
        const {subject, reply} = msg;
        const data = sc.decode(msg.data)
        const m = {subject, reply, data, key, time: new Date().toUTCString()}

        // console.log('m: ', m)
        // @ts-ignore
        // messages.push(m)
        messages.unshift(m)
        const a = messages.slice(0, 10)
        setMessages(a)
        // setMessages(messages)
        // setSubj(m.subject)
        // console.info(msg.subject)
    }
    // @ts-ignore
    const addStream = (err, msg) => {
        key2++
        const {subject, reply} = msg;
        const data = sc.decode(msg.data)
        const s = {subject, reply, data, key, time: new Date().toUTCString()}

        // console.log('m: ', m)
        // @ts-ignore
        // messages.push(m)
        streams.unshift(s)
        const a = streams.slice(0, 10)
        setStreams(a)
        // setMessages(messages)
        // setSubj(m.subject)
        // console.info(msg.subject)
    }

    // @ts-ignore
    const errHandler = (err, msg) => {

    }

    useEffect(() => {
        if (nc === undefined) {
            connect({servers: server, waitOnFirstConnect: true})
                .then(async (nc) => {


                    nc.subscribe("pn.ch1", {callback: addMessage})
                    // @ts-ignore
                    setConnection(nc)
                    getPlayList(nc)
                    // const opts = consumerOpts()
                    // opts.manualAck();
                    // opts.maxMessages(2);
                    // opts.deliverTo("xxx");
                    // opts.orderedConsumer()
                    // const opts = consumerOpts()
                    // opts.orderedConsumer()
                    // const sub = await nc.jetstream().subscribe("pn.ch1", opts)
                    // sub.callback(err, msg)
                    // const js = await nc.jetstream();
                    // setJs(js)

                    // for await(const m of sub) {
                    //     const data:any = jc.decode(m.data)
                    //     console.log('data: ', data)
                        // switch (data.type) {
                        //     case "draw":
                        //         if(data.id !== this.id) {
                        //             this.drawRaw(data)
                        //         }
                        //         break;
                        //     case "clear":
                        //         this.context.clearRect(0 ,0 ,window.innerWidth, window.innerHeight)
                        //     default:
                        //         break;
                        // }
                    // }
                    // const jsm:JetStreamManager = await nc.jetstreamManager();
                    // setJsm(jsm)
                    // const name:any = jsm.streams.find("my_stream");
                    // const si = await jsm.streams.info(name);
                    // console.log("subjects: ", si.config.subjects)
                    // const streams:any = await jsm.streams.list().next();
                    // const streams:any = await jsm.streams.list();
                    // setStreams(streams)
                    // const consumers:any = await jsm.consumers.list(streams[0]);
                    // setConsumers(consumers)
                })
                .catch((err) => {
                    setError("error connecting");
                    console.error(err)
                })
        }
    })

    async function getPlayList(netConnect?: NatsConnection) {
        if (nc?.hasOwnProperty('jetstream')) {
            netConnect = nc;
        }

        // @ts-ignore
        playlistSub = await netConnect.jetstream().pullSubscribe(PLAYLIST_ADDED, {
            mack: true,
            // artificially low ack_wait, to show some messages
            // not getting acked being redelivered
            config: {
                durable_name: 'durable',
                ack_policy: AckPolicy.Explicit,
                ack_wait: nanos(4000),
            }
        });
        setPlaylistPullSub(playlistSub)
        _getPlayList()
    }

    function _getPlayList(): any {
        _playlist1 = []
        // @ts-ignore
        let ackId =  document.getElementById("ackId").value;
        (async () => {
            if (!playlistSub) {
                playlistSub = playlistPullSub
            }
            if (playlistSub) {
            for await(const m of playlistSub) {
                console.log(
                    `[${m.seq}] ${
                        m.redelivered ? `- redelivery ${m.info.redeliveryCount}` : ""
                    }`,
                );
                const data: any = jc.decode(m.data)


                console.log('data: ', data)

                if (data.video === ackId) {
                    console.log('ackId matched...')
                    m.ack()
                    _playlist1.push(data.video);
                } else {
                    _playlist1.push(data.video);
                }
            }}
            // for await (const m of playlistSub) {
            //     console.log(
            //         `[${m.seq}] ${
            //             m.redelivered ? `- redelivery ${m.info.redeliveryCount}` : ""
            //         }`,
            //     );
            //     if (m.seq % 2 === 0) {
            //         m.ack();
            //     }
            // }
            console.log(_playlist1)
            return _playlist1
        })().then(r =>  {
            // @ts-ignore
            setPlayList1(_playlist1)
        });

        const fn = () => {
            console.log("[PULL]");
            if (playlistSub)
            playlistSub.pull({batch: 200, expires: 10000});
        };

// do the initial pull
        fn();

// and now schedule a pull every so often
//         const interval = setInterval(fn, 10000); // and repeat every 2s
    }

    async function showPlaylist() {
        if (!nc.hasOwnProperty('jetstream')) {
            connect({servers: server, waitOnFirstConnect: true})
                .then(async (nc) => {
                    playlistSub = await nc.jetstream().pullSubscribe(PLAYLIST_ADDED, {
                        mack: true,
                        // artificially low ack_wait, to show some messages
                        // not getting acked being redelivered
                        config: {
                            durable_name: 'durable',
                            ack_policy: AckPolicy.Explicit,
                            ack_wait: nanos(4000),
                        }
                    });
                    const fn = () => {
                        console.log("[PULL]");
                        if (playlistSub)
                            playlistSub.pull({batch: 200, expires: 10000});
                    };

                    fn();
                })
        // console.log('playlistResponse: ', getPlayList())
    } else{
            playlistSub = await nc.jetstream().pullSubscribe(PLAYLIST_ADDED, {
                mack: true,
                // artificially low ack_wait, to show some messages
                // not getting acked being redelivered
                config: {
                    durable_name: 'durable',
                    ack_policy: AckPolicy.Explicit,
                    ack_wait: nanos(4000),
                }
            });
            const fn = () => {
                console.log("[PULL]");
                if (playlistSub)
                    playlistSub.pull({batch: 200, expires: 10000});
            };

            fn();
        }
        return _getPlayList()
    }

    async function acknowledgeVideo() {
        _playlist1  = []
        // @ts-ignore
        playlistSub = await nc.jetstream().pullSubscribe(PLAYLIST_ADDED, {
            mack: true,
            // artificially low ack_wait, to show some messages
            // not getting acked being redelivered
            config: {
                durable_name: 'durable',
                ack_policy: AckPolicy.Explicit,
                ack_wait: nanos(4000),
            }
        });
        _getPlayList()
    }

    function addToQueue(action: string, videoId?: string, channelId?: any, keyNum?: any) {
        // action: string, videoId: string
        // let channel:string | undefined = channelId;
        console.log('videoId: ', videoId)
        throttle(() => {
            // const from = this.last
            const msg = {
                id: keyNum,
                type: action,
                // from: from,
                // to: to,
                video: videoId,
                playlist: []
            }
            console.log('msg: ', msg)

            // this.drawRaw(msg)
            // js?.publish(channelId, jc.encode(msg))
            nc.publish(channelId, jc.encode(msg))
            // nc.close()
            // this.last = to
        }, 30)()
    }

    function play(videoId: string) {
        console.log('play')
        let channelId:any =  document.getElementById('channelId')
        // let response = fetch(`http://localhost:3000/play/${videoId}`)
        // let response = fetch(`http://localhost:3000/play`);
        // console.log('response: ', response)
        addToQueue('play', videoId, channelId.value, key2++)
        // const msg = { id: key, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // nc.publish(subj, jc.encode(msg), { headers: h })
    }

    function pause(e: any) {
        console.log('pause')
        let channelId:any =  document.getElementById('channelId')
        addToQueue('pause', '', channelId.value)
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })

    }
    function next(e: any) {
        console.log('pause')
        let channelId:any =  document.getElementById('channelId')
        addToQueue('pause', '', channelId.value)
        // const ci = jsm?.consumers.info("ORDERS")
        // jsm?.consumers.streams.list().next()
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })

    }

    function changeVideo() {
        let videoId:any =  document.getElementById('videoId')
        let channelId:any =  document.getElementById('channelId')
        // let response = fetch(`http://localhost:3000/play/${videoId}`)
        console.log('channelId: ', channelId.value)
        console.log('videoId: ', videoId.value)
        // let videoId =  document.getElementById("videoId").value;
        addToQueue('change',  videoId.value, channelId.value, key2++)
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })
    }

    async function addToPlayList() {
        let videoId: any = document.getElementById('videoId')
        let channelId: any = document.getElementById('channelId')
        let playListNum = +1
        setPlaylistNum(playListNum)
        console.log('videoId: ', videoId.value)
        // let videoId =  document.getElementById("videoId").value;
        console.log('playlistNum: ', playlistNum)
        let response = await fetch(`http://localhost:3000/playlist/${videoId.value}`);
        console.log('response: ', response)
        // addToQueue('playlist',  videoId.value, channelId.value, playlistNum)
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })
    }


    function startPlaylist() {
        // let videoId =  document.getElementById("videoId").value;
        // this.playlist.push(videoId)
        console.log('start')
        // this.addToQueue('start',  this.playlist)
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })
    }
    // @ts-ignore
    function createPlaylist(e) {
        // let videoId =  document.getElementById("videoId").value;
        // this.playlist.push(videoId)
        console.log('start')
        // this.addToQueue('start',  this.playlist)
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })
    }



    // @ts-ignore
    function createStream(e) {
        // add a stream
        const stream = "default";
        const subj = `pn.ch1`;
        jsm?.streams?.add({ name: e.value, subjects: [subj] });
        // let videoId =  document.getElementById("videoId").value;
        // this.playlist.push(videoId)
        console.log('added stream/subject: ', stream + '/' + subj)
        // this.addToQueue('start',  this.playlist)
        // const msg = { id: this.id, type: "clear", }
        // const h = headers()
        // h.set("Nats-Rollup", "sub")
        // this.nats.publish(subject, this.jc.encode(msg), { headers: h })
    }
    // const {tableProps} = useTable<IPost>();
    // const [size, setSize] = useState<SizeType>('large'); // default is 'middle'
    const state = nc ? "connected" : "not yet connected"
    return (
        <div className="container">
            <h1 className="header"> {state}</h1>
            <h3>{lastError ? lastError : ""}</h3>
            <button className={`buttons`} onClick={() => play('v1xieje')}>Play</button>
            <button className={`buttons`} onClick={changeVideo}>Change Video</button>
            <button className={`buttons`} onClick={startPlaylist}>Start Playlist</button>
            <button className={`buttons`} onClick={addToPlayList}>Add to Playlist</button>
            <button className={`buttons`} onClick={() => pause('test')}>Pause</button>
            <button className={`buttons`} onClick={() => pause('test')}>Next</button>
            <input className={`buttons`} type="text" id="videoId" name="videoId" required placeholder="VideoId"/>
            {/*<input className={`buttons`} type="text" id="channelId" name="channelId" defaultValue={defaultChannel} required placeholder="ChannelId"/>*/}
            <select id="channelId">
                {/*<option value="default">default</option>*/}
                <option value="pn.ch1">pn.ch1</option>
                <option value="pn.ch2">pn.ch2</option>
            </select>
            <div>
                <button className={`buttons`} onClick={acknowledgeVideo}>Acknowledge</button>
                <input className={`buttons`} type="text" id="ackId" name="ackId" required placeholder="VideoId"/>
            </div>
            <button className={`buttons`} onClick={showPlaylist}>Show Playlist</button>
            {/*<div className={`messages`}>*/}
            {/*    <button className={`buttons`} onClick={() => createStream('default')}>Create Stream</button>*/}
            {/*</div>*/}
            {/*<div className={`messages`}>*/}
            {/*    <strong>STREAMS</strong>*/}
            {/*    {streams.length > 0 && lastError === ""*/}
            {/*        ? (*/}
            {/*            // @ts-ignore*/}
            {/*            <Streams streams={streams}/>*/}
            {/*        )*/}
            {/*        : (" No Streams")*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className={`messages`}>*/}
            {/*    <strong>CONSUMERS</strong>*/}
            {/*    {consumers.length > 0 && lastError === ""*/}
            {/*        ? (*/}
            {/*            // @ts-ignore*/}
            {/*            <Consumers consumers={consumers}/>*/}
            {/*        )*/}
            {/*        : (" No Consumers")*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={`messages`}>
                {messages.length > 0 && lastError === ""
                    ? (
                        // @ts-ignore
                        <Messages messages={messages}/>
                    )
                    : (" No Messages")
                }
            </div>
        </div>
        // <>

        // </>

    );
};
