import Message from "./Message"

// @ts-ignore
const Messages = ({ messages }) => {
    console.log('messages: ', messages)
    return (
        <>
            {messages.map((m: any) => (
                <Message key={m.key} msg={m}/>
            ))}
       </>
    );
};

export default Messages;